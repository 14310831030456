import React from 'react';

import Layout from '../components/Layout';
import config from '../../config';

const AboutPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>About Felipe Polo</h2>
        <p>{config.subHeading}</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p><b>I am an innovative Entrepreneur and Business Angel who has a passion for building winning teams and scalable organisations.</b></p>
          {/* <p><b>I bring over 15 years of experience building high-performance teams internationally to the role, transforming organisations through digital products and modern software delivery processes, using technology as a business lever and mentoring teams to enable them to reach their full potential.</b></p> */}
          <hr />
          <p>
          My career started with a bunch of organisations that taught me how NOT to lead teams, projects and companies. That nearly got me out of the software industry - until I was lucky enough to move to London, where my life completely changed: I understood there is a right way to set a working culture and SYSTEMS - only through building communities of highly motivated teams. 
          </p>
          <p>
          I used that knowledge to co-found a tech company called GuideSmiths in 2014, and launched the Madrid office in 2017. I managed to grow a development team up to 120+ people from scratch in 7 years and 3 countries. GuideSmiths quickly thrived and in March 2021 we sold our business to DCSL Software, creating DCSL GuideSmiths - now One Beyond.
          </p>
          <p>
          After the GuideSmiths venture, I came to realise how key building a great team is when it comes to growing, scaling up and selling a company. I learnt there is science behind the creation of high-performance teams, and so I created my new project <a href="https://teamhackers.es">Team Hackers</a> to help other businesses scale up.
          </p>
          {/* <p>
          After the GuideSmiths venture, I came to realise how hard and stressful is to scale such type of business. As I understand its problems, I decided to do something about it. My new mission is called <a href="https://mangroove.io">mangroove.io</a> and is here to help other digital agencies scale, supporting them at operating and selling - more, faster and better.
          </p> */}
          <p>
          I also invest in Startups as Business Angel and try to help them through mentoring in accelerators like Seedrocket, Demium or IE Venture Lab.
          </p>
          {/* <p>
          I truly believe in a healthy combination between people, business and tech to succeed. They need each other to be able to thrive, and that’s where my expertise lies.
          </p> */}
        </div>
      </section>
    </article>
  </Layout>
);

export default AboutPage;
